import type { SectionParams } from '@hubcms/domain-cook';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';

import { getTeaserLabelsVisibility } from './getTeaserLabelsVisibility';

type GetTeaserPropsOptions = {
  todaysDate: string;
  sectionParams?: SectionParams;
  environmentVariables?: Record<string, string>;
};

export function getTeaserPropsOptions({
  sectionParams = {},
  environmentVariables = {},
  todaysDate,
}: GetTeaserPropsOptions): TeaserPropsOptions {
  return {
    todaysDate,
    isVideoPreviewEnabled: environmentVariables.NEXT_PUBLIC_ENABLE_TEASER_VIDEO_PREVIEW === 'true',
    ...getTeaserLabelsVisibility(sectionParams),
  };
}
