import Head from 'next/head';
import type { ReactElement } from 'react';

import type { CookData } from '@hubcms/domain-cook';

type RSSLinkProps = {
  cookData: CookData;
};

export default function RSSLink({ cookData }: RSSLinkProps): ReactElement | null {
  if (cookData?.resolution?.context !== 'sec' || !cookData?.resolution?.section?.href) {
    return null;
  }

  return (
    <Head>
      <link
        rel="alternate"
        href={`${cookData?.resolution?.section?.href.replace(/\/$/, '')}/rss`}
        title="RSS"
        type="application/rss+xml"
      />
    </Head>
  );
}
