import getConfig from 'next/config';
import Head from 'next/head';
import type { ReactElement } from 'react';

import type { CookData } from '@hubcms/domain-cook';
import { getPageHref } from '@hubcms/feature-cook';

export default function MetaTagsAppLinks({ cookData }: { cookData: CookData }): ReactElement {
  const href = getPageHref({ data: cookData });
  const { publicRuntimeConfig } = getConfig();

  return (
    <Head>
      <meta property="al:android:package" content={publicRuntimeConfig.ANDROID_PACKAGE_APP_LINK} />
      {href && <meta property="al:android:url" content={href} />}
      {href && <meta property="al:ios:url" content={href} />}
      <meta property="fb:app_id" content={publicRuntimeConfig.FB_APP_ID} />
    </Head>
  );
}
