import type { NavItem } from '@hubcms/domain-navigation';

import { getSectionPreference } from './getSectionPreference';

export const mapMySectionToTopics = (item: NavItem): NavItem => {
  if (item.url) {
    return {
      ...item,
      url: getSectionPreference(item.url),
      clientUrl: getSectionPreference(item.url),
    };
  }
  return item;
};
