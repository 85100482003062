import type { ComponentProps } from 'react';

import { type CookData, type SectionParams, isArticleContext, isArticleOrExtraPageContext } from '@hubcms/domain-cook';
import { getSectionRegioIdentifier } from '@hubcms/utils-my-section';

import type { PianoLoader } from './components';

export function extractPianoHeadPropsFromCook(cookData: CookData): ComponentProps<typeof PianoLoader> {
  return {
    articleId: isArticleOrExtraPageContext(cookData.context) ? cookData.context.id : null,
    isPremium: getIsPremium(cookData.context),
    taxonomy: getTaxonomy(cookData.context),
    tagNames: getTagNames(cookData.context),
    zipCode: getZipCode(cookData.context, cookData.sectionParams),
  };
}

function getIsPremium(context: CookData['context']): boolean {
  return isArticleContext(context) && context.fields.isPremium;
}

function getTaxonomy(context: CookData['context']): string[] {
  if (!isArticleOrExtraPageContext(context)) {
    return [];
  }
  return context.allSections.map(section => getSectionSlug(section.href || ''));
}

function getSectionSlug(url: string): string {
  // Remove protocol, domain and optional trailing slash
  // https://www.brand.com/sport/soccer => /sport/soccer
  return url.replace(/^.*\/\/[^/]+(.*[^/])\/?$/, '$1');
}

function getTagNames(context: CookData['context']): string[] {
  if (!isArticleContext(context)) {
    return [];
  }
  return context.tags.map(tag => tag.name);
}

function getZipCode(context: CookData['context'], sectionParams: SectionParams): string | null {
  if (!isArticleContext(context)) {
    return null;
  }
  return getSectionRegioIdentifier(sectionParams) || null;
}
